<template>
  <div class="productDetail">
    <div class="title">{{ $t("prodDetail.title1") }}</div>
    <div class="content">
      <div class="left-img">
        <div class="imgs-list">
          <img
            v-show="!item.isMain"
            v-for="(item, index) in prodInfo.imgs"
            :key="index"
            :src="item.imaPath"
          />
        </div>
      </div>
      <div class="right-detail">
        <div class="detail-content">
          <div class="desc">
            <div class="prod-name">
              <h3>{{ $t("prodDetail.title2") }}：</h3>
              <span>{{ prodInfo.name }}</span>
            </div>
            <div class="prod-code">
              <h3 style="display: inline">{{ $t("prodDetail.title3") }}：</h3>
              <span>{{ prodInfo.number }}</span>
            </div>
            <div class="prod-description">
              <h3>{{ $t("prodDetail.title4") }}：</h3>
              <p v-html="`${prodInfo.description}`.replace(/(\r\n)|(\n)/g,'<br>')"></p>
            </div>
          </div>
        </div>
        <div class="left-img2">
          <div class="imgs-list">
            <img
              v-show="!item.isMain"
              v-for="(item, index) in prodInfo.imgs"
              :key="index"
              :src="item.imaPath"
            />
          </div>
        </div>
        <div class="connect-us">
          <!-- 联系代表 -->
          <div class="connect-deputy">
            <button>
              <a :href="'mailto:'+prodOtherInfo.productRepresentativeEmail">{{ $t("prodDetail.title5") }}</a>
            </button>
            <!-- live800 -->
            <a href="https://www.live800.com/" class="live">
              <i class="iconfont icon-kefu"></i>
            </a>
          </div>
          <h3>{{ $t("prodDetail.title6") }}</h3>
          <div class="compony">
            <p v-html="`${prodOtherInfo.productContactUs}`.replace(/(\r\n)|(\n)/g,'<br>')"></p>
          </div>
          <div class="other-explain">
            <h3>{{ $t("prodDetail.title7") }}：</h3>
            <div class="explain-text">
              <p v-html="`${prodOtherInfo.productOtherDescription}`.replace(/(\r\n)|(\n)/g,'<br>')"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <backTop></backTop>
  </div>
</template>

<script>
import BackTop from "../../../components/content/backTop/BackTop.vue";
import { getProdOtherInfo, getProdInfo } from "../../../network/category";
export default {
  name: "prod",
  data() {
    return {
      prodOtherInfo: {}, //产品其他信息
      prodInfo: {},
    };
  },
  created() {
    this.getProdOtherInfo();
    this.getProdInfo(this.$route.params.id);
    // console.log(this.$route.query.id);
  },
  methods: {
    //获取其他产品信息
    getProdOtherInfo() {
      getProdOtherInfo().then((res) => {
        // console.log(res);
        if (res.status !== "ok") {
          return this.$message.error("获取产品其他信息失败");
        }
        this.prodOtherInfo = res.data;
      });
    },
    //
    getProdInfo(id) {
      getProdInfo(id).then((res) => {
        // console.log(res.data);
        this.prodInfo = res.data;
      });
    },
  },
  components: {
    BackTop,
  },
};
</script>

<style scoped lang="less">
@import "../../../assets/css/category/ProductDetail.less";
</style>
